import * as React from "react"
import { useState, useEffect } from "react"
import { Link } from "gatsby"
import Layout from "../components/Layout"
import Seo from "../components/layout/Seo"
import { graphql, useStaticQuery } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { IconSearch } from "../components/icons/Icons"
import IconsProductPreloader from "../components/products/IconsProductPreloader"
import HeroImage from "../components/HeroImage"
import { uniformUrl } from "../helpers/StringHelpers"

const BlogPage = () => {
  const [limit, setLimit] = useState(18)
  const [keyword, setKeyword] = useState("")
  const [appliedKeywords, setAppliedKeywords] = useState([])

  const { blogs } = useStaticQuery(
    graphql`
      query {
        blogs: allStrapiPage(
          filter: { blog: { eq: true } }
          sort: { order: DESC, fields: updated_at }
        ) {
          nodes {
            title
            blog
            canonical_url
            meta_title
            meta_description
            slug
            hero_image_url
            created_at
            updated_at
          }
        }
      }
    `
  )
  const [filteredBlogs, setFilteredBlogs] = useState(blogs.nodes.filter((_c, i) => i < 18))
  const [maxBlogs, setMaxBlogs] = useState(blogs.nodes.length)

  const onSearch = e => {
    e.preventDefault()
    setLimit(18)
    setAppliedKeywords(keyword.toLowerCase().split(/\s/))
  }
  const extendLimit = () => {
    setLimit(l => l + 18)
  }
  useEffect(() => {
    let filtered = blogs.nodes.filter(blog => {
      return (
        appliedKeywords.length == 0 ||
        !appliedKeywords.find(
          k =>
            blog.title.toLowerCase().indexOf(k) === -1 &&
            blog.meta_title.toLowerCase().indexOf(k) === -1 &&
            blog.meta_description.toLowerCase().indexOf(k) === -1 &&
            blog.slug.toLowerCase().indexOf(k) === -1
        )
      )
    })
    setMaxBlogs(filtered.length)
    setFilteredBlogs(filtered.filter((_c, i) => i < limit))
  }, [limit, appliedKeywords])
  // decide what to do with hero_image_url
  return (
    <Layout
      breadcrumbs={true}
      className="mx-auto"
      canonical={"/blog/"}
      title={"Blog - How To Sell Luxury Watches and Jewelry Fast / Secure"}
    >
      <IconsProductPreloader />
      <Seo
        title={"Blog - How To Sell Luxury Watches and Jewelry Fast / Secure"}
        canonical={"/blog/"}
        description={
          "Sell your Watches and Jewelry for the most money. Gray and Sons Buying Division | Jewelry & Watch Specialists Blog. Sell Us Your Jewelry Today Fast and Secure."
        }
      />
      <section className="flex w-full flex-col items-center justify-center bg-gradient-to-tl from-[#ffe1c5] to-[#F8F6EC]">
        <div className=" max-w-[1366px] mx-auto w-full flex flex-col items-center justify-center lg:flex-row">
          <StaticImage
            src={"../images/sell-us-your-jewelry-banner.png"}
            alt={"Watches and Jewelry Image"}
            className={"mx-auto w-full max-w-[500px] aspect-square "}
          />

          <div className="flex w-10/12 mx-auto flex-col justify-center items-center gap-10 py-10 lg:py-0">
            <h1 className=" text-black text-[5rem] uppercase">Blogs</h1>
            <p className="text-link max-w-[500px] text-center text-xl uppercase">
              Learn about used rolex, cartier, patek philippe and other preowned watches, designer
              and estate jewelry, diamonds and much more!
            </p>
            <Link to={"/what-are-you-looking-to-sell/"} className="btn-cta mx-auto px-5 py-3">
              GET A QUOTE
            </Link>
          </div>
        </div>
      </section>
      <section className={"container mx-auto"}>
        <form action={"/blog"} method={"get"} onSubmit={onSearch}>
          <div
            className={
              "flex flex-row my-4 w-10/12 max-w-[800px] mx-auto border-b border-white justify-self-end"
            }
          >
            <label className={"sr-only"} htmlFor={"search-keywords"}>
              Search
            </label>
            <input
              type={"text"}
              name={"q"}
              placeholder={"Tell us, what are you looking for?"}
              value={keyword}
              id={"search-keywords"}
              onChange={e => {
                setKeyword(e.target.value)
              }}
              className={"flex-grow border border-accent px-4 placeholder-gray-500"}
            />
            <button className={"bg-accent text-white w-auto p-4"}>
              <IconSearch />
              <span className={"sr-only"}>Search</span>
            </button>
          </div>
        </form>
      </section>
      <div className={"container mx-auto flex gap-4"}>
        <section title={"Blog Posts list"} className={"flex w-full flex-wrap"}>
          {filteredBlogs.map(blog => {
            const slug = (blog.blog === true ? "blog/" : "") + blog.slug
            const canonicalUrl = blog.canonicalUrl || slug
            const finalUrl = uniformUrl("/" + canonicalUrl + "/")
            return (
              <article key={canonicalUrl} className={"block w-full md:w-1/2 lg:w-1/3"}>
                <Link to={finalUrl} className={"mx-auto block w-80"}>
                  {blog.hero_image_url || blog.hero_image?.url ? (
                    <HeroImage
                      url={blog.hero_image_url || blog.hero_image.url}
                      alt={blog.meta_title}
                      className={"h-80 w-80 rounded-2xl object-contain"}
                    />
                  ) : (
                    <StaticImage
                      src={"../images/top-watch-buyer-img.png"}
                      alt={blog.meta_title}
                      className={"h-80 w-80 rounded-2xl object-cover"}
                    />
                  )}
                </Link>
                <h2 className="mx-auto w-80 py-4">
                  <Link to={finalUrl}>{blog.title}</Link>
                </h2>
                <p className={"mx-auto w-80 pb-4"}>{blog.meta_description}</p>
                <Link className={"mx-auto block w-80"} aria-label={blog.title} to={finalUrl}>
                  Read more
                </Link>
              </article>
            )
          })}
          {limit < maxBlogs && (
            <button className={"btn-cta w-full p-4"} onClick={extendLimit}>
              Load More Posts
            </button>
          )}
        </section>
      </div>
      <section className="flex w-full flex-col justify-center py-10">
        <div className="flex w-full flex-col items-center justify-center ">
          <a href="tel:+13057706955" className="text-3xl font-bold text-accent hover:text-gray-500">
            <h3 className="px-10 text-center text-3xl uppercase">
              <a href={"tel:+13057706955"}>Call Us Now: 305 770 6955</a>
            </h3>
          </a>
          <br />
          <div className="w-full shadow-xl"> &nbsp;</div>
          <h2 className="py-10 text-center uppercase text-gray-800">
            The Purchasing and Consignment Division of Gray &amp; Sons <br />
          </h2>
          <br />
          <a href="https://www.grayandsons.com/catalog-request">
            <StaticImage
              src={"../images/svg/gray-and-sons-logo.svg"}
              alt={"Gray and Sons Jewelers"}
            />
          </a>
          <br /> <br /> <br />
          <h2 className="w-full py-10 text-center text-2xl font-black uppercase">
            Be the first to know | SEE OUR CATALOG
          </h2>
          <a
            href="https://www.grayandsons.com/catalog-request"
            className="flex w-full justify-center"
          >
            <StaticImage
              src={"../images/gray-and-sons-request-catalog-banner.jpeg"}
              alt={"Gray and Sons Catalog Subscription"}
            />
          </a>
        </div>
      </section>
    </Layout>
  )
}

export default BlogPage
